<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <OfferForm
      v-if="!isLoading"
      :offer="offer"
      v-on:addOrUpdateOffer="addOrUpdateOffer()"
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import OfferForm from "@/components/offers/OfferForm.vue";
import Offer from "@/models/offers/Offer";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    OfferForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      offer: new Offer(),
    };
  },
  props: ["offerToken"],
  methods: {
    async addOrUpdateOffer() {
      this.isLoading = true;
      try {
        const response = await this.offer.addOrUpdateOffer(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.offer.setInitialValue();
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "Offers" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getOfferDetails() {
      this.isLoading = true;
      this.offer.offerToken = this.offerToken;
      try {
        let response = await this.offer.getOfferDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.offer.fillData(response.data.offers);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "Offers" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "Offers" }).catch(() => {});
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getOfferDetails();
  },
};
</script>

<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'activityTypeToken'"
            :value="offer.brandData.activityTypeToken"
            :options="activityTypeTokenOptions"
            v-on:changeValue="activityTypeChanged($event)"
            :title="$t('activityTypes.select')"
            :imgName="'activity-types.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'brandToken'"
            :value="offer.brandToken"
            :options="brandTokenOptions"
            v-on:changeValue="brandChanged($event)"
            :title="$t('brands.select')"
            :imgName="'brand.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'brandServiceToken'"
            :value="offer.brandServiceToken"
            :options="brandServiceTokenOptions"
            v-on:changeValue="offer.brandServiceToken = $event"
            :title="$t('brandServices.select')"
            :imgName="'brand-services.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'offerPreferedTypeToken'"
            :value="offer.offerPreferedTypeToken"
            :options="preferedTypeTokenOptions"
            v-on:changeValue="offer.offerPreferedTypeToken = $event"
            :title="$t('selectPreferedType')"
            :imgName="'favorite-add.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'offerTitleAr'"
            :value="offer.offerTitleAr"
            :maxRows="maxRows"
            :title="$t('offerTitleAr')"
            :imgName="'offers.svg'"
            v-on:changeValue="offer.offerTitleAr = $event"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'offerTitleEn'"
            :value="offer.offerTitleEn"
            :maxRows="maxRows"
            :title="$t('offerTitleEn')"
            :imgName="'offers.svg'"
            v-on:changeValue="offer.offerTitleEn = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'fullCode'"
            :value="offer.fullCode"
            :title="$t('offerCode')"
            :imgName="'number.svg'"
            v-on:changeValue="offer.fullCode = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'offerDiscountPercentage'"
            :value="offer.offerDiscountPercentage"
            v-on:changeValue="offer.offerDiscountPercentage = $event"
            :title="$t('brands.discountPercentage')"
            :imgName="'maxDiscountPercentage.svg'"
          />

          <DatePicker
            :className="'col-md-6'"
            :id="'offerStartDate'"
            :value="offer.offerStartDate"
            v-on:changeValue="offer.offerStartDate = $event"
            :title="$t('startDate')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="offer.offerStartTime"
            v-on:changeValue="offer.offerStartTime = $event"
            :title="$t('startTime')"
            :language="language"
          />
          <DatePicker
            :className="'col-md-6'"
            :id="'offerEndDate'"
            :value="offer.offerEndDate"
            v-on:changeValue="offer.offerEndDate = $event"
            :title="$t('endDate')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="offer.offerEndTime"
            v-on:changeValue="offer.offerEndTime = $event"
            :title="$t('endTime')"
            :language="language"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'offerDescriptionAr'"
            :value="offer.offerDescriptionAr"
            :maxlength="textAreaMaxLenght"
            :maxRows="maxRows"
            :title="$t('offerDescriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="offer.offerDescriptionAr = $event"
          />
          <TextArea
            :className="'col-md-12'"
            :id="'offerDescriptionEn'"
            :value="offer.offerDescriptionEn"
            :maxlength="textAreaMaxLenght"
            :maxRows="maxRows"
            :title="$t('offerDescriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="offer.offerDescriptionEn = $event"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'offerNotes'"
            :value="offer.offerNotes"
            :maxlength="textAreaMaxLenght"
            :maxRows="maxRows"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="offer.offerNotes = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateOffer"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Offers' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import {
  BASE_URL,
  TEXT_MAX_LENGTH_SUPER,
  MAX_ROWS_TEXTAREA,
} from "@/utils/constants";
import ActivityTypes from "@/models/activityTypes/ActivityTypes";
import Brands from "@/models/brands/Brands";
import BrandServices from "@/models/brandServices/BrandServices";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { setDataMultiLang } from "@/utils/functions";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    DatePicker,
    TimePicker,
    CustomSelectBoxWithImg,
    CustomSelectBox,

    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      activityTypes: new ActivityTypes(),
      activityTypeTokenOptions: [],
      brands: new Brands(),
      brandTokenOptions: [],
      brandServices: new BrandServices(),
      brandServiceTokenOptions: [],
      preferedTypeTokenOptions: [],
      textAreaMaxLenght: TEXT_MAX_LENGTH_SUPER,
      maxRows: MAX_ROWS_TEXTAREA,
    };
  },
  props: ["offer", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.offer.mediaFile = file[0];
      }
    },
    async addOrUpdateOffer() {
      this.$emit("addOrUpdateOffer");
    },
    async getDialogOfActivityTypes() {
      this.isLoading = true;
      try {
        this.activityTypeTokenOptions =
          await this.activityTypes.activityType.getDialogOfActivityTypes(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    activityTypeChanged(activityTypeToken) {
      this.offer.brandData.activityTypeToken = activityTypeToken;
      // this.offer.brandToken = "";
      this.offer.brandServiceToken = "";
      this.brands.filterData.activityTypeToken = activityTypeToken;
      this.getBrandDialog();
    },

    async getBrandDialog() {
      this.isLoading = true;

      try {
        this.brandTokenOptions = await this.brands.brand.getBrandDialog(
          this.language,
          this.userAuthorizeToken,
          this.brands.filterData
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    brandChanged(brandToken) {
      this.offer.brandToken = brandToken;
      this.offer.brandServiceToken = "";
      this.brandServices.filterData.brandToken = brandToken;
      this.getBrandServiceDialog();
    },
    async getBrandServiceDialog() {
      this.isLoading = true;
      try {
        this.brandServiceTokenOptions =
          await this.brandServices.brandService.getBrandServiceDialog(
            this.language,
            this.userAuthorizeToken,
            this.brandServices.filterData
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfPreferedType() {
      this.isLoading = true;
      this.preferedTypeTokenOptions = [];
      this.preferedTypeTokenOptions.push({
        value: "",
        text: this.$t("selectPreferedType"),
      });
      let preferedTypes = this.constantsListsData.listPreferedType;
      for (let item in preferedTypes) {
        this.preferedTypeTokenOptions.push({
          value: preferedTypes[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            preferedTypes[item]["itemNameAr"],
            preferedTypes[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.imageSrc = this.offer.offerImagePath
      ? BASE_URL + this.offer.offerImagePath
      : this.offer.defaultImg;

    this.getDialogOfActivityTypes();
    this.getBrandDialog();
    this.getBrandServiceDialog();
    this.getDialogOfPreferedType();
  },
};
</script>

<style lang="scss"></style>
